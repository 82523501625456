import React from 'react';
import { graphql } from 'gatsby';

import Post from '../components/Post';
import Layout from '../components/Layout';

const nodes = query => (query ? query.edges : []).map(edge => edge.node);

const PostTemplate = ({ location, data }) => (
  <Layout
    location={location}
    breadcrumbs={data.ploneBreadcrumbs || []}
    metadata={data.site.siteMetadata}
    subjects={(data.ploneDocument && data.ploneDocument.subjects) || []}
  >
    <Post
      data={data.ploneDocument}
      images={nodes(data.allPloneImage)}
      files={nodes(data.allPloneFile)}
    />
  </Layout>
);

export default PostTemplate;

export const query = graphql`
  query PostTemplateQuery($path: String!, $duotone: String!) {
    site {
      siteMetadata {
        title
      }
    }
    ploneDocument: ploneClassicPage(_path: { eq: $path }) {
      id
      title
      description
      creators
      text {
        react
      }
      subjects
      effective(formatString: "MMMM Do, YYYY")
      image {
        publicURL
        childImageSharp {
          red: fixed(
            height: 800
            duotone: { shadow: "#002957", highlight: $duotone, opacity: 0 }
          ) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      _path
    }
    ploneBreadcrumbs(_path: { eq: $path }) {
      items {
        _id
        _path
        title
      }
    }
    allPloneImage(filter: { _backlinks: { eq: $path } }) {
      edges {
        node {
          ...Image
        }
      }
    }
  }
`;
